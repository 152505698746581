import React, { useState, useEffect } from "react"; // Fragment
import {
  // BrowserRouter as Router,
  // Route,
  // Routes,
  Link,
  useParams,
} from "react-router-dom";

import "./home.css";
import "bootstrap/dist/css/bootstrap.min.css";

const images = [
  "sip_stroll_0001.jpg",
  "skate_0001.jpg",
  "restaurant_0001.jpg",
  "beach_hang_0002.jpg",
  "silent_disco_ladies_0001.jpg",
];

const EVENT_API_URL = `https://4cljs7mcdi.execute-api.us-east-1.amazonaws.com/default/socialevents`;

const Home = () => {
  const [events, setEvents] = useState([]);
  const [currentEvents, setCurrentEvents] = useState([]);
  const [event, setEvent] = useState({});

  const { eventId } = useParams();

  useEffect(() => {
    async function fetchData() {
      // create a mock function that will return a list of events after a delay
      let useMocks = false;
      if (!useMocks) {
        if (eventId) {
          // get events from this api - specific event
          await fetch(`${EVENT_API_URL}?id=${eventId}`)
            .then((res) => res.json())
            .then((data) => {
              console.log("specific event data: ", data);
              if (data && data.socialEvents && data.socialEvents.Item) {
                console.log("setting event: ", data.socialEvents.Item);
                setEvent(data.socialEvents.Item);
              } else {
              }
            })
            .catch((err) => {
              console.log("err", err);
              return [];
            });
        }
        // get events from this api - all public events
        await fetch(EVENT_API_URL)
          .then((res) => res.json())
          .then((data) => {
            console.log("public events data: ", data);
            if (data && data.socialEvents) {
              // sort events by date
              data.socialEvents.sort((a, b) => {
                return new Date(a.date) - new Date(b.date);
              });
              setEvents(data.socialEvents);

              // set current events to only include events that have not passed
              const yesterday = new Date();
              yesterday.setDate(yesterday.getDate() - 1);
              const currentEvents = data.socialEvents.filter((eventItem) => {
                // if date is before yesterday, don't show it
                const eventDate = new Date(eventItem.date);
                if (eventDate < yesterday) {
                  return false;
                }
                return true;
              });
              setCurrentEvents(currentEvents);
            } else {
              console.log(
                "called public event api, but no events were returned"
              );
            }
          })
          .catch((err) => {
            console.log("err", err);
            return [];
          });
      }
    }
    fetchData();
  }, []);

  // function to convert date "May 29, 2023" to "Mon May 29"
  const convertDate = (date) => {
    const d = new Date(date);
    const day = d.toLocaleString("default", { weekday: "short" });
    const month = d.toLocaleString("default", { month: "short" });
    const dayOfMonth = d.getDate();
    return `${day} ${month} ${dayOfMonth}`;
  };

  const socialLinks = () => (
    <div className="social-links">
      <div className="link-container">
        <a
          href="https://www.meetup.com/jax-social-group-20s-30s/"
          target="_blank"
          rel="noopener noreferrer"
          className="btn btn-lg btn-block social-link"
          style={{
            fontSize: "18px",
            backgroundColor: "#ED1C40",
            color: "white",
          }}
        >
          <div className="icon-container">
            <i className="fa fa-calendar"></i>
          </div>
        </a>
        <p className="link-label small">Meetup Events</p>
      </div>

      <div className="link-container">
        <a
          href="https://www.facebook.com/JaxSocialGroup"
          target="_blank"
          rel="noopener noreferrer"
          className="btn btn-lg btn-block social-link"
          style={{
            fontSize: "18px",
            backgroundColor: "#4267B2",
            color: "white",
          }}
        >
          <div className="icon-container">
            <i className="fab fa-facebook"></i>
          </div>
        </a>
        <p className="link-label small">Facebook</p>
      </div>

      <div className="link-container">
        <a
          href="https://www.instagram.com/jaxsocialgroup/"
          target="_blank"
          rel="noopener noreferrer"
          className="btn btn-lg btn-block social-link"
          style={{
            fontSize: "18px",
            backgroundColor: "#C13584",
            color: "white",
          }}
        >
          <div className="icon-container">
            <i className="fab fa-instagram"></i>
          </div>
        </a>
        <p className="link-label small">Instagram</p>
      </div>
    </div>
  );

  const membershipCard = () => (
    <a
      href="/memberships"
      rel="noopener noreferrer"
      className="btn btn-lg btn-block btn-success custom-btn"
    >
      <div>Join as Member</div>
      <div style={{ fontSize: ".6em" }}>
        men ${process.env.REACT_APP_MEMBERSHIPCOST_USD} / mo
      </div>
      <div
        style={{
          fontSize: ".6em",
          fontStyle: "italic",
          fontWeight: "bold",
          textDecoration: "underline",
        }}
      >
        women FREE
      </div>
    </a>
  );

  const upcomingEvents = () => (
    <>
      <div className="home" style={{ paddingBottom: "10px" }}>
        {currentEvents.length > 0 ? (
          <></>
        ) : (
          <>
            {events.length < 1 ? (
              <div className="text-center">
                <i className="fa fa-spinner fa-spin fa-3x"></i>
              </div>
            ) : (
              <div className="text-center">
                <p>No upcoming events near Jacksonville</p>
              </div>
            )}
          </>
        )}
        {/* display each element in events array in a compact bootstrap card */}
        {currentEvents.slice(0, 3).map((eventItem, i) => {
          // if date is before yesterday, don't show it
          const yesterday = new Date();
          yesterday.setDate(yesterday.getDate() - 1);
          const eventDate = new Date(eventItem.date);
          if (eventDate < yesterday) {
            return null;
          }
          return (
            <div
              className="card py-2 px-3 mb-2"
              key={i}
              style={{
                width: "100%",
                border: "3px solid purple",
                borderRadius: "10px",
              }}
            >
              <div className="card-body p-0">
                <a
                  href={`/events/${eventItem.socialEventId}`}
                  className="text-decoration-none text-dark"
                >
                  <small className="font-weight-bold">{eventItem.title}</small>
                  <br />
                  <small className="text-muted">
                    {convertDate(eventItem.date)} @ {eventItem.time} @{" "}
                    {eventItem.location}
                  </small>
                </a>
              </div>
            </div>
          );
        })}
        <a href="/events" style={{ color: "black" }}>
          FULL EVENT LIST &gt;&gt;
        </a>
      </div>
    </>
  );

  return (
    <div className="outer-home-container">
      <div className="home">
        <div className="inner-home-container">
          {/* <div
            style={{
              textAlign: "center",
              marginBottom: "-10px",
              marginTop: "10px",
            }}
          >
            <h1 className="home-title" style={{ fontSize: "20px" }}>
              {process.env.REACT_APP_GROUP_NAME}
            </h1>
          </div> */}

          {/* {upcomingEvents()} */}
          {/* {membershipCard()} */}

          {/* <div style={{ padding: "10px" }}>
            {images.map((src, index) => (
              <div
                key={index}
                style={{ marginBottom: "20px" }} // 20px space between images
              >
                <img
                  src={`https://s3.amazonaws.com/social-images-public/${src}`}
                  alt={`image-${index}`}
                  style={{
                    maxWidth: "100%", // make image responsive
                    height: "auto", // auto adjust the height
                  }}
                />
              </div>
            ))}
          </div> */}

          {/* {membershipCard()} */}

          {/* create two buttons that are side by side on a single line */}
          {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="col" style={{ flex: 1 }}>
              <a
                href="/memberships"
                rel="noopener noreferrer"
                className="btn btn-lg btn-block btn-default"
                style={{ fontSize: "1.3em" }}
              >
                Memberships
              </a>
            </div>
            <div style={{ width: "20px" }}></div>
            <div className="col" style={{ flex: 1 }}>
              <a
                href="/pay"
                rel="noopener noreferrer"
                className="btn btn-lg btn-block btn-primary"
                style={{ fontSize: "1.3em" }}
              >
                Pay
              </a>
            </div>
          </div> */}

          <br />
          <br />

          {socialLinks()}
        </div>

        {/* footer */}
        <div className="footer">
          {/* <p>
            &copy; {new Date().getFullYear()} {process.env.REACT_APP_GROUP_NAME}
          </p> */}
          <p>
            <a
              href="http://activebeaches.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Web design by David Hudman
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Home;
