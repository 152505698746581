/* eslint-disable jsx-a11y/href-no-hash */
import React, { Fragment } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./styles.css";

// import Navbar from "./components/layout/Navbar";
import NavbarCustom from "./components/NavbarCustom/NavbarCustom";
// import Home from "./components/pages/Home";
import Home from "./components/pages/Home/Home";
import App1 from "./components/pages/App1/App1";
// eslint-disable-next-line import/no-named-as-default
import App2 from "./components/pages/App2/App2";
import App3 from "./components/pages/App3/App3";
import Pay from "./components/pages/Pay/Pay";
import Membership from "./components/pages/Membership/Membership";
import MembershipMen from "./components/pages/MembershipMen/MembershipMen";
import MembershipWomen from "./components/pages/MembershipWomen/MembershipWomen";
import BchMembership from "./components/pages/BchMembership/BchMembership";
import Map from "./components/pages/Map/Map";
import Terms from "./components/pages/Terms/Terms";
import Privacy from "./components/pages/Privacy/Privacy";
import Example from "./components/pages/Example/Example";
import Login from "./components/pages/Login/Login";
import Events from "./components/pages/Events/Events";

const App = () => (
  <Router>
    <Fragment>
      <NavbarCustom />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        {/* <Route path="/pay" element={<Membership />} /> */}
        {/* Events path */}
        {/* <Route path="/events" element={<Events />} />
        <Route path="/events/:eventId" element={<Events />} />
        <Route path="/login" element={<Login />} />
        <Route path="/example" element={<Example />} /> */}
        {/* If you ever change /privacy or /tos - update google cloud app links for those */}
        {/* <Route path="/tos" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} */}
      </Routes>
    </Fragment>
  </Router>
);

export default App;
